@import '../../sass/Variable/index';

.wrapper {
  button {
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    transition: 0.25s;
    width: 100%;
    height: 48px;
  }
}
// pri
.primary {
  button {
    background: #2c9e3e;
    width: 255px;
    transition: all 0.3s ease-in-out;
  }
  &:hover button {
    background: #2c9e3e;
    color: white;
  }
  // margin-right: 20px;
}
// white
.white {
  button {
    width: 255px;
    font-size: 16px;
    line-height: 24px;
    background: #fff;
    border: 1px solid #2c9e3e;
    box-sizing: border-box;
    border-radius: 6px;
    color: #2c9e3e;
    font-weight: 600;
    transition: all 0.3s ease-in-out;

    &:hover {
      border: none;
      background: #2c9e3e;
      color: #fff;
    }
  }
}

.login {
  button {
    height: 42px;
    width: unset;
    padding: 10px;
    background: #2c9e3e;
    font-weight: 500;
  }
}
