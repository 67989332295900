@import '../Variable';
.wrapper-header {
  position: relative;
  padding: 10px;
  min-height: 100vh;
  box-shadow: $box-shadow;
  width: $width-header;

  @include mobile {
    width: unset;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 3px;
    height: 100%;
    width: 3px;
    background-color: #efefef;
  }
}

@include mobile {
  .wrapper-menu {
    max-height: 90vh;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
}

.image-logo {
  width: 130px;
  height: 60px;

  @include tablet {
    width: 112px;
    height: 60px;
  }
  @include mobile {
    width: 140px;
    height: 54px;
  }
}

.icon-close {
  opacity: 0;
  visibility: hidden;
  @include mobile {
    opacity: 1;
    visibility: visible;
    margin-left: 20px;
    cursor: pointer;
    float: right;
    svg {
      width: 40px;
      height: 40px;
      color: #93a195;
    }
  }
}

.menu-item {
  @include flex(center, flex-start, nowrap);
  line-height: 24px;
  margin-top: 30px;
  font-size: 1.8rem;
  padding: 0 4px;
  position: relative;

  .wrapper-opacity {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  @include mobile {
    width: 100%;
  }

  a {
    @include flex(center, flex-start, nowrap);
    height: 60px;
    padding: 3px;
    width: 100%;
    border-radius: 16px;
    transition: all 0.3s linear;
    &:hover {
      background-color: #e9f7eb;
      cursor: pointer;
    }
  }
}

.item.active {
  background-color: $color-hover;
  span {
    color: $main-color;
    font-weight: bold;
  }
}

.menu-icon {
  color: #cecece;
  margin-right: 20px;
  font-size: 2rem;
  width: 44px;
  margin-left: 4px;
}

.menu-title {
  color: #757c76;
  font-size: 2.5rem;
  font-weight: 500;
}

// header notext

@import '../Variable';
.wrapper-headernotext {
  position: relative;
  padding: 10px;
  min-height: 100vh;
  box-shadow: $box-shadow;
  width: $width-headernotext;

  @include mobile {
    width: unset;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 3px;
    height: 100%;
    width: 3px;
    background-color: #efefef;
  }
}

@include mobile {
  .wrapper-menu {
    max-height: 90vh;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
}

.image-logonotext {
  width: 79px;
  height: 60px;
  object-fit: contain;

  @include tablet {
    width: 79px;
    height: 60px;
  }
  @include mobile {
    width: 140px;
    height: 54px;
  }
}

.icon-close {
  opacity: 0;
  visibility: hidden;
  @include mobile {
    opacity: 1;
    visibility: visible;
    margin-left: 20px;
    cursor: pointer;
    float: right;
    svg {
      width: 40px;
      height: 40px;
      color: #93a195;
    }
  }
}

.menu-item {
  @include flex(center, flex-start, nowrap);
  line-height: 24px;
  margin-top: 30px;
  font-size: 1.8rem;
  // padding: 0 10px;

  @include mobile {
    width: 100%;
  }

  a {
    @include flex(center, flex-start, nowrap);
    height: 60px;
    padding:0px 3px;
    width: 100%;
    border-radius: 16px;
    transition: all 0.3s linear;
    &:hover {
      background-color: #e9f7eb;
      cursor: pointer;
    }
  }
}

.item.active {
  background-color: $color-hover;
  // padding: 0 4px;
  span {
    color: $main-color;
    font-weight: bold;
  }
}

.menu-icon {
  color: #cecece;
  margin-right: 14px;
  font-size: 2rem;
  width: 44px;
}

.menu-title {
  color: #757c76;
  font-size: 2.5rem;
  font-weight: 500;
}
