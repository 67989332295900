@import '../Variable/index';

.wrapper-layoutfooter {
  // display: flex;
  min-height: 100vh;
  // width: 100vw;
}

.wrapper-header {
  // min-width: 300px;
  @include tablet {
    // width: 100vw;
  }
  @include mobile {
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 316px;
    z-index: 100;
    background: #ffffff;
    transform: translateX(-100%);
    transition: all 0.3s linear;
  }
  &.active {
    @include mobile {
      transform: translateX(0);
      width: 100vw;
    }
  }
}

.wrapper-content {
  // flex: 1;
}
