@import '../Variable/index';

.wrapper {
  box-shadow: inset 0 -1px 0 hsla(0, 0%, 68%, 0.25);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: white;

  z-index: 2;
  .container-header {
    height: 100%;
    .row-header {
      height: 100%;
      .col-left {
        figure {
          width: 132px;
          height: 100%;
          margin: 0;
          @include flex();
          img {
            width: 100%;
          }
        }
      }
      .col-right {
        @include flex(center, space-around, nowrap);

        .list-menu {
          @include flex(center, space-around, nowrap);
          flex: 1;

          li {
            position: relative;
            &:hover a {
              color: #2c9e3e;
            }
            &.active a {
              color: #2c9e3e;
            }
            &:hover::after {
              background-color: #2c9e3e;
              width: 100%;
            }
            a {
              padding: 20px 12px;
              display: block;
              padding: 20px;
              font-size: 1.7rem;
              font-weight: 500;
              color: #4e4c50;
              text-transform: capitalize;
              line-height: 20px;
              position: relative;
              transition: all 0.3s ease-in-out;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0;
              height: 2px;
              background: #2c9e3e;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        .wrapper-sub {
          @include flex(center, space-around, nowrap);
          height: 100%;
          flex: 1;

          .search-btn {
            @include flex(center, center, nowrap);
            border: 1px solid rgba(0, 0, 0, 0.63);
            border-radius: 10px;
            margin-right: 20px;
            padding: 0 10px;
            input {
              height: 40px;
              border-radius: 10px;
              padding: 4px 10px;
              width: 120px;
            }
            span {
              cursor: pointer;

              svg {
                font-size: 2.4rem;
                transition: all 0.2s ease;
                &:hover {
                  color: #2c9e3e;
                }
              }
            }
          }
          .price-btn {
            button {
              width: 120px;
              height: 40px;
              margin-right: 24px;
              border: 1px solid #2c9e3e;
              border-radius: 6px;
              font-weight: 500;
              color: #2c9e3e;
              font-size: 1.7rem;
              background-color: white;
              transition: all 0.3s ease-in-out;
              &:hover {
                background-color: #2c9e3e;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
