$main-bg: #fff;
$main-color: #2c9e3e;
$sub-color: #f1f6fa;
$plus-color: #ff9800;
$color-hover: #e9f7eb;
$color-defaultlayout: #f1f6fa;

$txt-main-color: #000;
$txt-second-color: #8d8d8d;
$txt-white: #fff;

$btn-main-bg: $main-color;
$btn-main-color: #fff;

$header-height: 170px;
$header-tablet-height: 70px;
$header-mobile-height: 40px;
$header-shrink-height: 70px;
$width-header: 240px;
$width-headernotext: 100px;

$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

$mobile-width: 600px;
$tablet-width: 1024px;
$pc-width: 1600px;

$numbers: (0, 1, 2, 3, 4, 5, 6, 7, 8);
