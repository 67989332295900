@import '../Variable/index';

@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem;

  @include mobile {
    font-size: 1.6rem;
  }
  line-height: 1.5rem;
  text-rendering: optimizeSpeed;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}

.animationscroll {
  overflow-y: auto !important;
  max-height: 100vh;
  transition: all 1s linear;

  &::-webkit-scrollbar {
    width: 1rem;
    height: 20px !important;
    display: block !important;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 100vw;
    height: 20px !important;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
span,
strong,
div {
  font-family: 'Roboto', san-serif !important;
}

iframe {
  .ytp-cued-thumbnail-overlay-image {
    -webkit-background-size: contain !important;
    background-size: contain !important;
  }
}

// css Layout

.container {
  max-width: calc(100% - $width-header);
  @include mobile {
    max-width: 100% !important;
  }
}

.container-header__notext {
  max-width: calc(100% - $width-headernotext);
  @include mobile {
    max-width: 100% !important;
  }
}
//

// default css

li {
  list-style: none;
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
  text-transform: capitalize;
}

a {
  line-height: 24px;
  text-decoration: none;
  transition: all 0.3s linear;
  &:hover {
    color: unset;
  }
}
input,
* {
  outline: none;
  border: none;
  user-select: none;
}

.swiper {
  height: 100%;
  width: 100%;
}

.swiper-list-item {
  .swiper-wrapper {
    & .swiper-slide:nth-child(n + 2) {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: -20%;
        height: 80%;
        width: 4px;
        background-color: #1861b7;
      }
      // border-left: 1px solid #1861b7;
    }
  }
}

// .swiper {
//   .swiper-wrapper {
//     .swiper-slide {
//       width: 24.4% !important;

//       @include tablet {
//         width: 49% !important;
//       }
//       @include mobile {
//         width: 49% !important;
//       }
//     }
//   }
// }

// .form-left__content__swiper {
//   .swiper {
//     .swiper-wrapper {
//       .swiper-slide {
//         width: 100% !important;

//         @include tablet {
//           width: 100% !important;
//         }
//       }
//     }
//   }
// }

.swiper-pagination {
  @include flex();
  .swiper-pagination-bullet {
    background-color: #f91d00;
    transition: all 0.3s linear;
    border: 2px solid white;
    width: 14px;
    height: 14px;

    &-active {
      background-color: rgb(245, 144, 4);
      border: 2px solid #ff6652;
      width: 12px;
      height: 12px;
    }
  }
}

.swiper-button-prev {
  // color: white;
  left: 10px;
  background-image: url('../../assets/image/Swiper/pre.png');
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.3s linear;
  &:hover {
    transform: scale(1.1);
  }
}
.swiper-button-next {
  right: 10px;
  background-image: url('../../assets/image/Swiper/next.png');
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transition: all 0.3s linear;
  &:hover {
    transform: scale(1.1);
  }
}
.swiper-home {
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    left: -10px;
    top: 84%;
    transform: translateY(-50%);
  }
  .swiper-button-next {
    width: 40px;
    height: 40px;
    right: -10px;
    top: 84%;
    transform: translateY(-50%);
  }
}

.mySwiper {
  .swiper-button-prev {
    width: 60px;
    height: 60px;
    left: 3px;
  }
  .swiper-button-next {
    width: 60px;
    height: 60px;
    right: 6px;
  }
}
.marquee-container {
  overflow: hidden !important;
  display: flex !important;
}

.marquee {
  min-width: 200%;
}

// paging

.item-bookgrid {
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  margin-bottom: 3rem;

  @include tablet {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.music {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 5rem;
  flex-wrap: wrap;
  font-size: 1.2rem;
  gap: 20px 10px;
}

.page-num {
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #1f966f;
  font-size: 1.6rem;
  border-radius: 8px;

  li a {
    font-size: 1.6rem;
  }
  &:hover {
    background-color: #1f966f;
    color: #fff;
  }
}

.pagination .active {
  background-color: #1f966f;
  color: white;
}

.Toastify__toast-container {
  margin-right: 100px;

  @include tablet {
    margin-right: 40px;
  }
}

.ytp-pause-overlay {
  display: none;
}

iframe {
  border-radius: 10px !important;
}

.swal2-confirm {
  background-color: rgb(64, 60, 249) !important;
}

//
input[type='date']::-webkit-datetime-edit-text {
  color: #697a8d;
}
input[type='date']::-webkit-datetime-edit-month-field {
  color: #697a8d;
}
input[type='date']::-webkit-datetime-edit-day-field {
  color: #697a8d;
}
input[type='date']::-webkit-datetime-edit-year-field {
  color: #697a8d;
}

//

// swiper page intro
.mySwiper {
  .swiper-button-next,
  .swiper-button-prev {
    width: unset !important;
    height: unset !important;
    transition: all 0.1s linear;
    border-radius: 50%;
  }

  .swiper-button-next:after {
    content: '>' !important;
    font-size: 2.8rem !important;
    @include mobile {
      font-size: 2.2rem;
    }
  }

  .swiper-button-prev:after {
    content: '<' !important;
    font-size: 2.8rem !important;
    @include mobile {
      font-size: 2.2rem;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-family: unset !important;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    @include flex();
    line-height: unset !important;
    color: black;

    @include mobile {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

//Phuong
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root,
.css-3eghsz-PrivatePickersYear-button,
.css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabe,
.css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel,
.css-dplwbx-MuiPickersCalendarHeader-label {
  font-size: 16px !important;
}
.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon,
.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  font-size: 18px !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #2c9e3e !important;
}
.css-10d9dml-MuiTabs-indicator {
  background-color: transparent !important;
}

// swiper game
.swiper-startgame {
  margin-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
  width: 80%;
  height: unset !important;

  .swiper-button-next {
    display: none;
  }
  .swiper-button-prev {
    display: none;
  }
}

// swiper select
.swiper-select {
  margin-top: 60px;
  padding-left: 50px;
  padding-right: 50px;

  .swiper-button-prev {
    background-image: url('../../assets/image/SelectGame/left.png');
    background-size: 70%;
    width: 60px;
    height: 90px;
    left: -7px;
    top: 36%;
  }
  .swiper-button-next {
    background-image: url('../../assets/image/SelectGame/right.png');
    width: 60px;
    height: 90px;
    right: 0px;
    top: 36%;
    background-size: 70%;
  }
}
